<mat-drawer-container autosize class="light-theme">
  <mat-drawer #drawer [opened]="!minimized" [mode]="minimized ? 'over' : 'side'">
    <i class="close al-icon al-ico-dialog-x sm-md" *ngIf="minimized" (click)="drawer.close()"></i>
    <div class="list-container">
      <sm-graph-settings-bar
        class="drawer-settings-bar"
        *ngIf="!minimized"
        [verticalLayout]="true"
        [smoothWeight]="smoothWeight$ | async"
        [xAxisType]="xAxisType$ | async"
        [groupBy]="groupBy"
        [groupByOptions]="groupByOptions"
        (changeWeight)="changeSmoothness($event)"
        (toggleSettings)="toggleSettingsBar()"
        (changeXAxisType)="changeXAxisType($event)"
        (changeGroupBy)="changeGroupBy($event)"
      ></sm-graph-settings-bar>
      <sm-selectable-grouped-filter-list
        [list]="scalarList"
        [selected]="selectedGraph"
        [checkedList]="listOfHidden | async"
        [searchTerm]="searchTerm$ | async"
        (itemSelect)="metricSelected($event)"
        (hiddenChanged)="hiddenListChanged($event)"
        (searchTermChanged)="searchTermChanged($event)"
      >
      </sm-selectable-grouped-filter-list>
    </div>
  </mat-drawer>
  <mat-drawer-content class="overflow-hidden">
    <sm-graph-settings-bar
      class="ribbon-setting-bar"
      [class.showSettings]="showSettingsBar && minimized"
      [smoothWeight]="smoothWeight$ | async"
      [xAxisType]="xAxisType$ | async"
      [groupBy]="groupBy"
      [groupByOptions]="groupByOptions"
      (changeWeight)="changeSmoothness($event)"
      (toggleSettings)="toggleSettingsBar()"
      (changeXAxisType)="changeXAxisType($event)"
      (changeGroupBy)="changeGroupBy($event)"
    ></sm-graph-settings-bar>
    <div class="graphs-container" [class.maximized]="!minimized">
      <div class="hover-button" *ngIf="minimized">
        <button
          class="btn btn-secondary"
          (click)="drawer.open()"
          smTooltip="Toggle Graphs"
        >
          <i class="al-icon al-ico-eye-outline"></i>
        </button>
      </div>
      <sm-experiment-graphs
        [class.with-settings]="showSettingsBar && minimized"
        #experimentGraphs
        [isGroupGraphs]="false"
        [metrics]="graphs"
        [singleValueData]="singleValueData$ | async"
        [experimentName]="experimentName"
        [hiddenList]="listOfHidden | async"
        [smoothWeight]="smoothWeight$ | async"
        [legendStringLength]="minimized? 14 : undefined"
        [minimized]="minimized"
        [xAxisType]="xAxisType$ | async"
        [groupBy]="groupBy"
        [splitSize]="splitSize$ | async"
        [breakPoint]="900"
        (resetGraphs)="resetMetrics()"
      >
      </sm-experiment-graphs>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
