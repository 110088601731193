<div smClickStopPropagation class="d-flex justify-content-between hyper-params-container">
  <i (click)="goBack.emit()" class="al-icon sm-md al-ico-back pointer" smClickStopPropagation></i>
  <div class="hyper-param-header">SELECT HYPER PARAMETERS TO DISPLAY
  </div>
</div>
<hr style="margin: 0">
<sm-grouped-checked-filter-list
  smClickStopPropagation
  [itemsList]="hyperParams"
  [selectedItemsList]="metricsCols"
  (selectedItems)="toggleParamToDisplay($event)"
  (clearSelection)="clearSelection.emit()"
></sm-grouped-checked-filter-list>
