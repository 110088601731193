<mat-expansion-panel [expanded]="true">
  <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="40px" class="active">
    USER PROPERTIES
  </mat-expansion-panel-header>
  <div
    queryParamsHandling="preserve"
    [routerLink]="'hyper-param/properties'"
    [class.selected]="selectedHyperParam === 'properties'"
    class="hyper-container pointer ellipsis"
    routerLink="hyper-param"
  >
    <i class='sm al-icon al-ico-data-audit al-color'
       [class.white]= "selectedHyperParam === 'properties'"
       [class.blue-400]="selectedHyperParam !== 'properties'">
    </i>Properties
  </div>
</mat-expansion-panel>
<mat-expansion-panel expanded="true" *ngIf="((hyperParams | keyvalue)|filterOut:'key':'properties')?.length>0">
  <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="40px" class="active">
    HYPER PARAMETERS
  </mat-expansion-panel-header>
  <div
    *ngFor="let hyperParameter of (hyperParams | keyvalue)|filterOut:'key':'properties' | sort:'key'; trackBy: trackByFn"
    queryParamsHandling="preserve"
    [routerLink]="'hyper-param/' + (hyperParameter.key | safeUrlParameter)"
    [class.selected]="selectedHyperParam === (hyperParameter.key | safeUrlParameter)"
    class="hyper-container pointer ellipsis"
    routerLink="hyper-param"
  >
    <i
      class="sm al-icon al-ico-data-audit al-color"
      [class]="(selectedHyperParam === hyperParameter.key ? 'white' : 'blue-400')"
    ></i>{{hyperParameter.key | replaceViaMapPipe:sectionReplaceMap}}
  </div>
</mat-expansion-panel>

<mat-expansion-panel [expanded]="true" *ngIf="(configuration | keyvalue)?.length>0">
  <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="40px" class="active">
    CONFIGURATION OBJECTS
  </mat-expansion-panel-header>
  <div
    *ngFor="let confObj of configuration | keyvalue; trackBy: trackByFn"
    queryParamsHandling="preserve"
    [routerLink]="'configuration/' + (confObj.key | safeUrlParameter)"
    [class.selected]="selectedObject === confObj.key"
    class="hyper-container pointer ellipsis"
    routerLink="configuration"
  >
    <i class="sm al-icon al-ico-data-audit al-color" [class]="(selectedObject === confObj.key ? 'white' : 'blue-400')"></i>{{confObj.key | replaceViaMapPipe:sectionReplaceMap}}
  </div>

</mat-expansion-panel>
