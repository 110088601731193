<div class="dynamic-subtitle">
  <div class="line">Move <span class="p-name">{{projectName | shortProjectName}}</span> from <span
    class="p-name">{{projectName | projectLocation}}</span></div>
  <div class="line">To <span *ngIf="project.parent; else placeH" class="p-name">{{project.parent}}</span>
    <ng-template #placeH><span class="p-holder">Project…</span></ng-template>
  </div>
</div>
<form #moveToForm="ngForm" (submit)="send()">
  <mat-form-field class="w-100"
                  appearance="outline"
                  hideRequiredMarker="true"
                  (click)="project.parent && clear(); projectInputRef.blur(); projectInputRef.focus()">
    <mat-label>Destination</mat-label>
    <input matInput type="text"
           [matAutocomplete]="auto"
           [ngModel]="project.parent"
           name="projectName"
           placeholder="Search for project path"
           #projectInputRef
           #projectInput="ngModel"
           smUniquePathValidator
           [currentName]="projectName | shortProjectName"
           [existingPaths]="projectsNames"
           smUniqueNameValidator
           smInvalidPrefixValidator
           [invalidPrefix]="projectName"
           [existingNames]="[projectName | projectLocation]"
           required
           minlength="3"
           (keydown.enter)="projectInput.control.markAsTouched()"
    >
    <span matSuffix *ngIf="projectInput.value && (!isAutoCompleteOpen) && !(project.parent | stringIncludedInArray:projectsNames)" class="creat-new-suffix">(Create New)</span>
    <i matSuffix [class]="'fa mr2 ' + (project.parent? 'fa-times pointer':'fa-search')"
       (click)="project.parent && clear(); projectInputRef.focus()"
       smClickStopPropagation></i>
    <mat-error *ngIf="projectInput?.errors?.required">Please provide a Project</mat-error>
    <mat-error *ngIf="projectInput?.errors?.minlength">Please provide a project name longer than 3 characters</mat-error>
    <mat-error *ngIf="projectInput?.errors?.uniqueName">Can't move a project to its current location</mat-error>
    <mat-error *ngIf="projectInput?.errors?.invalidPrefix">Can't move a project to its nested projects</mat-error>
    <mat-error *ngIf="projectInput?.errors?.uniquePath">{{projectInput.value}} already contains a project named {{projectName | shortProjectName}}</mat-error>
    <mat-autocomplete #auto="matAutocomplete"
                      name="ExperimentProject"
                      (opened)="setIsAutoCompleteOpen(true)"
                      (closed)="setIsAutoCompleteOpen(false)"
                      (optionSelected)="locationSelected($event)"
                      class="light-theme"
                      classList="light-theme"
                      autoActiveFirstOption
    >
      <mat-option
        class="item"
        *ngIf="(projectInput.value && !(projectInput.value | stringIncludedInArray:projectsNames))"
        [value]="projectInput.value"
        (onSelectionChange)="createNewSelected($event)"
      >"{{projectInput.value}}" <span class="new">(Create New)</span></mat-option>
      <mat-option *ngFor="let projectName of projectsNames | simpleFilter:projectInput.value" [value]="projectName" [smTooltip]="projectName"  smShowTooltipIfEllipsis (onSelectionChange)="optionSelected($event)">
        <div [innerHTML]="projectName | highlightSearchText:projectInput.value"></div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
<div class="buttons d-flex justify-content-center">
  <button [disabled]="projectName === project?.parent || moveToForm.invalid" (click)="send()" cdkFocusInitial
          class="btn btn-neon yes-button" #moveButton>
    MOVE
  </button>
  <button (click)="closeDialog()" class="btn btn-outline-neon">
    CANCEL
  </button>

</div>
