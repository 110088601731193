<div class="sm-card-list-header">
  <div class="recent-title">
    <sm-menu [header]="sortByTitle" class="menu" panelClasses="light-theme">
      <sm-menu-item itemLabel="Recent" (itemClicked)="orderByChanged.emit('last_update')" [sortOrder]="sortByTitle==='RECENT' && sortOrder"></sm-menu-item>
      <sm-menu-item itemLabel="Name" (itemClicked)="orderByChanged.emit('basename')" [sortOrder]="sortByTitle==='NAME' && sortOrder"></sm-menu-item>
    </sm-menu>
    <sm-show-only-user-work></sm-show-only-user-work>
  </div>
  <div>
    <ng-content></ng-content>
  </div>
</div>
