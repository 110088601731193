<div class="single-table-container " (mouseenter)="setHover(true)" (mouseleave)="setHover(false)">
  <div class="summary-header position-relative">Summary
    <i class="al-icon al-ico-csv pointer al-color blue-400" (click)="downloadTableAsCSV()" [class.show]="hover"></i>
  </div>

  <div class="d-flex items-container">
    <div *ngFor="let item of data" class="item">
      <div class="variant">{{item.variant}}</div>
      <div class="value">{{item.value}}</div>
    </div>
  </div>
</div>
