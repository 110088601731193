<sm-overlay [backdropActive]="backdropActive"></sm-overlay>
<div class="d-flex">
  <div class="middle-col">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center model-name-cont">
        <div class="p-2 icon i-model model-icon l-40"></div>
        <sm-inline-edit
          class="edit-name"
          [originalText]="model?.name"
          [editable]="editable"
          (textChanged)="onNameChanged($event)"
          (inlineActiveStateChanged)="editExperimentName($event)"
        >
          <div class="model-name">
            <span>{{model?.name}}</span>
          </div>
        </sm-inline-edit>
      </div>
      <div class="d-flex align-items-center">
        <sm-id-badge class="mr-3" [id]="model?.id" (copied)="copyToClipboard()" *ngIf="!viewId"></sm-id-badge>
        <sm-model-menu-extended
          class="line-item"
          [model]="model"
          [selectedModel]="model"
          [tagsFilterByProject]="tagsFilterByProject$ | async"
          [selectedDisableAvailableIsMultiple]="false"
          [selectedDisableAvailable]="selectedDisableAvailable"
          [projectTags]="projectTags$ | async"
          [companyTags]="companyTags$ | async"
          [numSelected]="1"
          (tagSelected)="addTag($event)">
        </sm-model-menu-extended>
        <div (click)="closeInfoClicked.emit()" class="d-flex align-items-center line-item">
          <i class="al-icon al-ico-dialog-x pointer"></i>
        </div>
      </div>
    </div>
    <div class="tags-list">
      <sm-tag-list
        *ngIf="editable; else readOnlyTags"
        class="w-100"
        [class.menu-open]="tagMenu?.trigger.menuOpen"
        [sysTags]="sysTags"
        [tags]="model?.tags"
        (add)="openTagMenu($event)"
        (remove)="removeTag($event)"></sm-tag-list>
      <ng-template #readOnlyTags>
        <sm-tag-list
          class="w-100"
          [sysTags]="sysTags"
          [tags]="model?.tags"></sm-tag-list>
      </ng-template>
      <sm-menu
        #tagMenu
        [showCart]="false"
        [showButton]="false"
        [showOverlay]="false"
        (menuClosed)="tagsMenuClosed()"
      >
        <sm-tags-menu
          class="light-theme"
          #tagsMenuContent
          [tags]="model?.tags"
          [tagsFilterByProject]="tagsFilterByProject$ | async"
          [projectTags]="projectTags$ | async"
          [companyTags]="companyTags$ | async"
          (tagSelected)="addTag($event)"
        ></sm-tags-menu>
      </sm-menu>
    </div>
  </div>
</div>
